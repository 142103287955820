import { Language } from 'services/languages';
import { contains, dynamicNamespace, DynamicOrder, equals, mergeFilters } from 'utils/dynamic';
import {
  apiRtk,
  behaviourMoveRows,
  decoratorRank,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_PROJECT_TYPES, IProjectType, ProjectType } from './models';

const REVALIDATE_KEY = 'ProjectTypes' as const;

const dynamic = dynamicNamespace<IProjectType>();

export * from './models';

type ApiModel = ProjectType;

class Service extends DynamicService<ApiModel> {
  moveRows = async (newRows: Partial<Language>[], oldRows: Partial<Language>[]) => {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: this.patch,
    });
  };
  @decoratorRank('rank')
  async post(data: ApiModel) {
    return super.post(data);
  }
}

export const ServiceProjectTypes = new Service({
  getAll: API_PROJECT_TYPES.GET_ALL_DYNAMIC,
  post: API_PROJECT_TYPES.POST,
  patch: API_PROJECT_TYPES.PATCH,
  delete: API_PROJECT_TYPES.DELETE,
});

export const apiProjectTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectTypesSource: build.query<Pick<ApiModel, 'id' | 'title'>[], void>({
      query: () => ({
        url: API_PROJECT_TYPES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title'),
          filter: dynamic.makeFilter('isActive', true, equals),
          orderBy: dynamic.orderBy('rank', 'asc'),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridProjectTypes: build.query<
      DynamicResult<ApiModel, { count: true }>,
      {
        search: string;
        take: number;
        skip: number;
        order: DynamicOrder;
      }
    >({
      queryFn: async ({ search, take, skip, order }) => {
        try {
          const params = {
            filter: mergeFilters(dynamic.makeFilter(['title'], search, contains)).join('&&'),
            select: dynamic.select('rank', 'title', 'id', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceProjectTypes.getAllDynamic<ApiModel, typeof params>(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getProjectType: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceProjectTypes.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postProjectType: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceProjectTypes.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchProjectType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectTypes.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteProjectType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectTypes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    moveProjectTypes: build.mutation<
      void,
      { newRows: Partial<ApiModel>[]; oldRows: Partial<ApiModel>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceProjectTypes.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id })),
      ],
    }),
  }),
});
