import { select } from 'utils/dynamic';
import { apiRtk, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_USER_PERMISSIONS, UserPermissions } from './models';

export * from './models';

type Model = UserPermissions;
const REVALIDATE_TAG = 'UserCmsProfilePermissions';

type Source = Pick<Model, 'id' | 'title'>;

class Service extends DynamicService<Model> {}

export const ServiceUserPermissions = new Service({
  getAll: API_USER_PERMISSIONS.GET_ALL_DYNAMIC,
  post: API_USER_PERMISSIONS.POST,
  patch: API_USER_PERMISSIONS.PATCH,
  delete: API_USER_PERMISSIONS.DELETE,
});

export const apiUserPermissions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserCmsProfilePermissionSource: build.query<Source[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserPermissions.getAllDynamic<Source>({
            select: select<Model>('id', 'title'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserCmsProfilePermissionGrid: build.query<Model[], void>({
      query: () => ({
        url: API_USER_PERMISSIONS.GET_ALL_DYNAMIC,
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserCmsProfilePermission: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceUserPermissions.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
  }),
});
