import { Box, Stack, Typography } from '@mui/material';
import { HeaderPortal } from 'components/header-portal';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const ThankYouLayout = memo(() => {
  const { tp } = useTranslate();

  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.THANK_YOU.path,
        to: APP_ROUTES.THANK_YOU().config,
        label: tp('general'),
      },
      {
        value: APP_ROUTES.THANK_YOU_SEO.path,
        to: APP_ROUTES.THANK_YOU_SEO().config,
        label: tp('seo'),
      },
    ];
  }, [tp]);

  return (
    <>
      <HeaderPortal>
        <Stack height={'100%'} justifyContent={'center'}>
          <Typography variant={'h2'} component={'h1'} noWrap>
            {tp('thank-you')}
          </Typography>
        </Stack>
      </HeaderPortal>
      <Box className={style.root}>
        <Box className={style.inner}>
          <TabsPage tabs={tabs} />
        </Box>
      </Box>
    </>
  );
});
