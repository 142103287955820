import { Box, Stack, Typography } from '@mui/material';
import { HeaderPortal } from 'components/header-portal';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const TeamMembersLayout = memo(() => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.TEAM_MEMBERS.path,
        to: APP_ROUTES.TEAM_MEMBERS().config,
        label: tp('general'),
      },
      {
        value: APP_ROUTES.TEAM_MEMBERS_USERS.path,
        to: APP_ROUTES.TEAM_MEMBERS_USERS().config,
        label: tp('team-members'),
      },
    ];
  }, [tp]);
  return (
    <>
      <HeaderPortal>
        <Stack height={'100%'} justifyContent={'center'}>
          <Typography variant={'h2'} component={'h1'} noWrap>
            {tp('team-members')}
          </Typography>
        </Stack>
      </HeaderPortal>
      <Box className={style.root}>
        <Box className={style.inner}>
          <TabsPage tabs={tabs} />
        </Box>
      </Box>
    </>
  );
});
