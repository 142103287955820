import { useMemo } from 'react';
import { DIRECTION } from 'services/languages';
import { selectAccountLanguageID } from 'store/auth';
import { useAppSelector } from './redux';
import { useSourceLanguages } from './use-rtk-sources';

export const useDetectLanguage = () => {
  const languageID = useAppSelector(selectAccountLanguageID);
  const { data } = useSourceLanguages();
  return useMemo(() => {
    const language = data.find(({ id }) => id === languageID);
    const isRtl = language ? language.direction === DIRECTION.RTL : false;
    return { isRtl, isLtr: !isRtl, languageID };
  }, [data, languageID]);
};
