import { useAppDispatch } from 'hooks';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { ServiceAccounts } from 'services/accounts';
import { actionAccountClear } from 'store/auth';
import { getAuthTokens, updateAuthTokens } from 'utils/cookies';
import { AxiosInterceptorPatch } from './patch';
import { AxiosInterceptorPost } from './post';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { AxiosInterceptorsToken } from './token';

interface Props {
  children: React.ReactNode;
}

export const AxiosInterceptorsProvider = memo<Props>(({ children }) => {
  const [error, setError] = useState<Error>();
  const dispatch = useAppDispatch();
  const onRefresh = useCallback(async () => {
    const { token: Token, refreshToken: RefreshToken, expires } = getAuthTokens();

    if (!Token || !RefreshToken || !expires) {
      throw new Error('token-not-found');
    }

    const {
      data: { token, refreshToken },
    } = await ServiceAccounts.refreshToken({
      token: Token,
      refreshToken: RefreshToken,
    });

    return { token, refreshToken, expires };
  }, []);

  useEffect(() => {
    if (error) {
      updateAuthTokens();
      dispatch(actionAccountClear());
      setError(undefined);
    }
  }, [error, dispatch]);

  return (
    <AxiosInterceptorsToken>
      <AxiosInterceptorsRefreshToken
        onRefresh={onRefresh}
        onError={setError}
        onSuccess={updateAuthTokens}
      >
        <AxiosInterceptorPatch>
          <AxiosInterceptorPost>{children}</AxiosInterceptorPost>
        </AxiosInterceptorPatch>
      </AxiosInterceptorsRefreshToken>
    </AxiosInterceptorsToken>
  );
});
