import { AxiosRequestConfig } from 'axios';
import React, { memo, useEffect } from 'react';
import { apiApp, prepareRequestData } from 'utils/service';

const instances = [apiApp];
const patchDotNet5 = (conf: AxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object.entries(prepareRequestData(conf.data))
      .filter(([, value]) => value !== undefined)
      .map(([path, value]) => {
        return { path, value };
      });
  }
  return conf;
};

interface Props {
  children: React.ReactNode;
}
export const AxiosInterceptorPatch = memo<Props>(({ children }) => {
  useEffect(() => {
    const interceptors = instances.map((instance) => {
      const id = instance.interceptors.request.use(patchDotNet5);
      return { instance, id };
    });
    return () => {
      interceptors.forEach(({ id, instance }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{children}</>;
});
