import { useCallback, useState } from 'react';

export const useCallbackOnce = <T extends (...args: any[]) => any>(cb: T) => {
  const [isDone, setIsDone] = useState(false);
  const wrapCb = useCallback(
    (...args: any[]) => {
      setIsDone(true);
      return cb(...args);
    },
    [cb],
  );

  return isDone ? undefined : wrapCb;
};
