import { format } from 'date-fns';
import { convertToDate, isDateLike } from 'utils/dates';
import { Paths } from 'utils/types';

class MergeFilters {
  private value: Array<string | undefined>;
  constructor(value: Array<string | undefined>) {
    this.value = value.filter(Boolean);
  }
  join(separator: string) {
    if (this.value.length === 0) {
      return undefined;
    }
    return createStart(this.value.length, this.value.join(separator));
  }
}

type ValueCreator = (name: string, value: any | any[]) => string | undefined;

export const select = <T extends Record<string, any>>(...args: (Paths<T> | string)[]) => {
  return args.join(',').replace(/  +/g, ' ').replace(/\n/gm, '');
};

export interface DynamicOrder<F extends string = string> {
  field: F;
  order: 'desc' | 'asc' | null;
}
export const orderBy = (name: string, order?: 'desc' | 'asc' | null) => {
  if (!order) {
    return undefined;
  }
  const names = name.split(',');
  return names.map((name) => [name, order].join(' ')).join(',');
};

const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query === '' ? undefined : query;
};

export const makeFilter = <T extends Record<string, any>>(
  name: Paths<T, 5> | Paths<T, 5>[],
  value: any | any[],
  valueCreator: ValueCreator,
): string | undefined => {
  const names = Array.isArray(name) ? name : [name];

  if (value === undefined) {
    return undefined;
  }

  const values = names.map((_n) => valueCreator(String(_n), value)).filter(Boolean);

  return createStart(values.length, values.join('||'));
};

export const dynamicNamespace = <T extends Record<string, any>>() => {
  return {
    select: (...args: (Paths<T> | string)[]) => select<T>(...args),
    makeFilter: (
      name: Paths<T, 5> | Paths<T, 5>[],
      value: any | any[],
      valueCreator: ValueCreator,
    ) => makeFilter<T>(name, value, valueCreator),
    orderBy: (name: string, order?: 'desc' | 'asc' | null) => orderBy(name, order),
  };
};
export const mergeFilters = (...filters: (string | undefined)[]) => {
  return new MergeFilters(filters);
};

// Value creators
export const more: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}>${value}`;
};
export const moreOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}>=${value}`;
};
export const less: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<${value}`;
};
export const lessOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<=${value}`;
};
export const equals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}==${innerValue}`;
};
export const notEquals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}!=${innerValue}`;
};
export const contains: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}.contains("${prepareValue(String(value))}")`;
};
export const dateRange: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range: any[] = value.filter(isDateLike);

  if (range.length < 2) {
    return undefined;
  }
  const [start, end] = range;
  return [
    `(${name} >= DateTime(${format(convertToDate(start), 'yyyy,MM,dd,00,00,00')})`,
    `${name} <= DateTime(${format(convertToDate(end), 'yyyy,MM,dd,23,59,59')}))`,
  ].join('&&');
};

// decorators
export const decoratorIsNotNullable = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([value === null, value === ''].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return result;
};
export const decoratorIsNumber = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([!isFinite(value as any)].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorValueArray = (creator: ValueCreator, separator = '||') => {
  return ((name, value) => {
    if (!Array.isArray(value)) return undefined;
    if (value.length === 0) return undefined;
    return createStart(
      value.length,
      value.map((v) => makeFilter(name, v, creator)).join(separator),
    );
  }) as ValueCreator;
};
export const decoratorExclude = (creator: ValueCreator, excludeValue: any) => {
  return ((name, value) => {
    if (value === excludeValue) return undefined;
    return creator(name, value);
  }) as ValueCreator;
};
