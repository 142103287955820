import { APP_ROUTES } from 'configs';
import { useAppSelector } from 'hooks/redux';
import React, { memo, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAccountUser } from 'store/auth';

interface Props {
  children: React.ReactNode;
}
export const RequireAuth = memo<Props>(({ children }) => {
  const user = useAppSelector(selectAccountUser);
  const location = useLocation();

  const redirect = useMemo(() => {
    return [location.pathname, location.search].filter((v) => v).join('?');
  }, [location.pathname, location.search]);

  if (!user) {
    return <Navigate to={APP_ROUTES.LOGIN({ redirect }).config} replace={true} />;
  }
  return <>{children}</>;
});
