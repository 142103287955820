import { ThemeOptions } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import variables from 'styles/config.scss';

const appBreakpoints = {
  values: {
    xs: Number(variables.xs),
    sm: Number(variables.sm),
    md: Number(variables.md),
    lg: Number(variables.lg),
    xl: Number(variables.xl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,

  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: '4rem',
      lineHeight: 1.2,
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '2.4rem',
      lineHeight: 1.33,
      fontWeight: 500,
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },

    subtitle1: {
      fontSize: '1.6rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.4rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: 1.4,
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: 1.25,
    },
  },

  palette: {
    background: {
      default: variables.colorPageBackground,
    },
    primary: {
      main: variables.colorPrimary,
      dark: variables.colorSecondary,
      contrastText: '#fff',
    },
    secondary: {
      main: variables.colorSecondary,
      dark: variables.colorPrimary,
      contrastText: '#fff',
    },
    success: {
      main: variables.colorGreen,
    },
    error: {
      main: variables.colorRed,
    },
    text: {
      secondary: variables.colorGrey,
    },

    tonalOffset: 0.05,
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        containedPrimary: {
          borderRadius: '.4rem',
        },
        root: {
          fontSize: '1.4rem',
          lineHeight: '1.429',
          fontWeight: 500,
          letterSpacing: '0.125rem',
        },
        sizeLarge: {
          minHeight: '4.8rem',
        },
        sizeMedium: {
          minHeight: '3.6rem',
        },
        sizeSmall: {
          letterSpacing: '0.01rem',
          fontSize: '1.2rem',
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'secondary',
        size: 'small',
        autoComplete: 'off',
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.8em',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1.8em',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderBottomColor: variables.colorGreyLight,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          borderColor: '#E9EEF0',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: variables.colorGrey,
          letterSpacing: '0.04rem',
        },
        shrink: {
          color: variables.colorGrey,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          marginTop: '0.2rem',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: '1.4rem',
        },
        clearIndicator: {
          color: variables.colorSecondaryLight,
        },
        popupIndicator: {
          color: variables.colorSecondaryLight,
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2.4rem',
        },
        fontSizeSmall: {
          fontSize: '1.5rem',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: '3.2rem',
          minWidth: '3.2rem',
          fontSize: '1.2rem',
          fontWeight: 400,
          margin: '0 0.5rem',
          backgroundColor: variables.colorGreyLight2,
          color: variables.colorPrimary,
        },
        icon: {
          fontSize: '2.2rem',
        },
        text: {
          lineHeight: '2.4rem',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: variables.boxShadowDarkBlue,
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: variables.colorSecondaryLightest,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        // sizeMedium: {
        //   padding: '0.6rem',
        // },
        root: {
          '&.Mui-disabled': {
            color: variables.colorGrey,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.8rem',
        },
        paperFullScreen: {
          borderRadius: '0',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2rem ',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 2rem 2rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '1.6rem 2rem',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: variables.boxShadowDarkBlue,
          borderRadius: '0',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '4px',
          padding: '2.2rem 0',
          marginBottom: 0,
        },
        track: {
          border: 'none',
        },
        thumb: {
          height: '1.6rem',
          width: '1.6rem',
        },
        rail: {
          height: '2px',
          opacity: 1,
        },
        mark: {
          width: '1px',
          height: '4px',
          background: '#fff',
        },
        markLabel: {
          fontSize: '1.2rem',
          top: '-.4rem',
          '@media (pointer: coarse)': {
            top: '-.4rem',
          },
        },
        colorPrimary: {
          '.MuiSlider-markLabel': {
            color: variables.colorPrimary,
          },
          '.MuiSlider-rail': {
            backgroundColor: variables.colorGrey,
          },
          '.MuiSlider-track': {
            backgroundColor: variables.colorSecondary,
          },
        },
        colorSecondary: {
          '.MuiSlider-markLabel': {
            color: variables.colorSecondary,
          },
          '.MuiSlider-rail': {
            backgroundColor: variables.colorGrey,
          },
          '.MuiSlider-track': {
            backgroundColor: variables.colorPrimary,
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        gutters: {
          [breakpoints.up('xs')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6re',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
          [breakpoints.up('sm')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('lg')]: {
            paddingLeft: '3rem',
            paddingRight: '3rem',
          },
        },
      },
    },
  },
};
