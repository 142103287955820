import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import { Avatar, Box, MenuItem, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { MenuButton } from 'components/menu-button';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useCurrentUser, useMountedRef, useOpen, useTranslate } from 'hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { actionAccountLogout } from 'store/auth';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

const MenuProps = {
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  variant: 'menu',
} as const;

interface Props {}

export const HeaderProfile: React.FC<Props> = () => {
  const { tp } = useTranslate();
  const { name, userPhoto } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useMountedRef();
  const dispatch = useAppDispatch();

  const onLogout = useCallback(async () => {
    setIsLoading(true);

    await dispatch(actionAccountLogout());

    if (!mountedRef.current) return;
    setIsLoading(false);
  }, [mountedRef, dispatch]);

  const menuItems = useMemo(() => {
    return [
      {
        title: tp('account-settings'),
        component: RouterLink,
        to: APP_ROUTES.PROFILE().link,
      },
      { title: tp('log-out'), disabled: isLoading, onClick: onLogout },
    ];
  }, [tp, isLoading, onLogout]);

  const { isOpen, onOpen, onClose } = useOpen();
  return (
    <MenuButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      items={menuItems}
      MenuProps={MenuProps}
    >
      <MenuItem component={'button'} className={style.root}>
        <Tooltip title={name}>
          <Box className={style.avatar}>
            <Avatar sx={{ width: 34, height: 34 }} src={userPhoto || ''} />
          </Box>
        </Tooltip>
        <ArrowDropDownTwoToneIcon
          className={clsx(style.icon, isOpen && style.iconOpen)}
          color={'inherit'}
          sx={{ color: VARS.colorGrey }}
        />
      </MenuItem>
    </MenuButton>
  );
};
