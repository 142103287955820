import { useEffect, useState } from 'react';

export const useTimer = () => {
  const [timer, setTimer] = useState<number>();

  useEffect(() => {
    const timerID =
      timer && timer > 0
        ? setTimeout(() => {
            setTimer((v) => (v ? v - 1 : 0));
          }, 1000)
        : undefined;
    return () => {
      timerID && clearTimeout(timerID);
    };
  }, [timer]);

  return [timer, setTimer] as const;
};
