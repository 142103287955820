import { AppLoading } from 'components/app-loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { memo, useEffect } from 'react';
import { apiLabels } from 'services/labels';
import { apiLanguages } from 'services/languages';
import { actionAccountSetLanguageID, selectAccountLanguageID } from 'store/auth';

interface Props {
  children: React.ReactNode;
}

export const TranslateProvider = memo<Props>(({ children }) => {
  const dispatch = useAppDispatch();
  const languageID = useAppSelector(selectAccountLanguageID);
  const { isSuccess: isSuccessLanguages, data: languages } = apiLanguages.useGetAllLanguagesQuery();

  const defaultLanguageID = languages ? languages[0]?.id : undefined;

  const { isSuccess: isSuccessLabels } = apiLabels.useGetAppLabelsQuery(
    { languageID: languageID || '' },
    { skip: !languageID },
  );

  useEffect(() => {
    if (!languageID && defaultLanguageID) {
      dispatch(actionAccountSetLanguageID(defaultLanguageID));
    }
  }, [languageID, defaultLanguageID, dispatch]);

  const isReady = isSuccessLanguages || isSuccessLabels;

  return (
    <>
      {isReady && children}
      {!isReady && <AppLoading />}
    </>
  );
});
