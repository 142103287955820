import * as yup from 'yup';

const API = 'Languages';

export const API_LANGUAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  CREATE: `${API}/Create`,
  PATCH: (data: Pick<Language, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Language, 'id'>) => `${API}/Delete/${data.id}`,
};
export enum DIRECTION {
  RTL = 2,
  LTR = 1,
}

export const schemaLanguages = yup.object({
  title: yup.string().required('rule-required'),
  icon: yup.mixed().nullable().required('rule-required'),
  culture: yup.string().required('rule-required'),
});

export class Language implements yup.InferType<typeof schemaLanguages> {
  id!: string;
  isActive = true;
  title = '';
  direction = DIRECTION.LTR;
  culture = '';
  icon = '';
  currency = '';
  importName = '';
  isoCode = '';
  rank = 0;
}
export interface ILanguage extends Language {}

export interface IGridLanguage
  extends Pick<ILanguage, 'id' | 'title' | 'culture' | 'rank' | 'isActive' | 'icon'> {}
