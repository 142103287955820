import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),

  HOME_PAGE: createTypedRoute('/home-page'),
  HOME_PAGE_FACTS: createTypedRoute('/home-page/facts'),
  HOME_PAGE_SEO: createTypedRoute('/home-page/seo'),

  ABOUT_US: createTypedRoute('/about-us'),
  ABOUT_US_WORK_FLOWS: createTypedRoute('/about-us/work-flows'),

  TEAM_MEMBERS: createTypedRoute('/team-members'),
  TEAM_MEMBERS_USERS: createTypedRoute('/team-members/users'),
  CONTACT_US: createTypedRoute('/contact-us'),
  THANK_YOU: createTypedRoute('/thank-you'),
  THANK_YOU_SEO: createTypedRoute('/thank-you/seo'),
  SETTINGS: createTypedRoute('/settings'),
  CMS_USERS: createTypedRoute('/settings/cms-users'),
  CMS_USER_ITEM: createTypedRoute<{ appUserID: string | 'new' }>('/settings/cms-users/:appUserID'),
  PERMISSIONS: createTypedRoute('/settings/permissions'),
  PROJECT_TYPES: createTypedRoute('/settings/project-types'),
  LEADS: createTypedRoute('/settings/leads'),

  PROJECTS_LIST: createTypedRoute('/projects/list'),
  PROJECTS_ITEM: createTypedRoute<{ projectID: string | 'new' }>('/projects/list/:projectID'),

  PROJECT_PAGE: createTypedRoute('/projects/project-page'),
  PROJECT_PAGE_SPECIALIZATIONS: createTypedRoute('/projects/project-page/specializations'),

  LABELS: createTypedRoute('/labels'),
  CMS: createTypedRoute('/labels/cms'),
  LANGUAGES: createTypedRoute('/labels/languages'),

  SYSTEM_SETTINGS: createTypedRoute('/settings/system-settings'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),

  PROFILE: createTypedRoute('/profile'),
};
