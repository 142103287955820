import React from 'react';

import { Divider, Stack } from '@mui/material';
import { NativeScroll } from 'components/native-scroll';
import { NavigationItem } from '../navigation-item';
import style from './index.module.scss';

interface Option {
  title: string;
  Icon?: React.ReactNode;
  to?: any;
  options?: Option[];
}
interface Props {
  options: Option[];
  isMenuOpen: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
}

export const Navigation: React.FC<Props> = ({ options, isMenuOpen, onMenuOpen, onMenuClose }) => {
  return (
    <NativeScroll trackVisibility={'all'} sx={{ overflowX: 'hidden' }}>
      <Stack className={style.root} divider={<Divider sx={{ ml: 8.4, mr: 2 }} />}>
        {options.map((option, i) => {
          return (
            <NavigationItem
              key={i}
              Icon={option.Icon}
              options={option.options}
              title={option.title}
              isMenuOpen={isMenuOpen}
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              to={option.to}
              level={1}
            />
          );
        })}
      </Stack>
    </NativeScroll>
  );
};
