import React, { useMemo } from 'react';

import { List, ListItemButton, ListItemText, ListProps } from '@mui/material';
import clsx from 'clsx';
import { useDetectLanguage, useSourceLanguages } from 'hooks';
import style from './index.module.scss';

interface Props extends ListProps {}

export const Languages: React.FC<Props> = ({ className, ...rest }) => {
  const { data } = useSourceLanguages();
  const { languageID } = useDetectLanguage();

  const currentLanguage = useMemo(
    () => data.find((lang) => lang.id === languageID),
    [data, languageID],
  );

  return (
    <List {...rest} className={clsx(style.root, className)}>
      <ListItemButton selected sx={{ px: 0 }}>
        <ListItemText sx={{ textAlign: 'center', color: 'white' }}>
          {currentLanguage?.title}
        </ListItemText>
      </ListItemButton>
    </List>
  );
};
