import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';

interface Classes {
  root: string;
}

interface Props extends IconButtonProps {
  className?: string;
  classes?: Partial<Classes>;
}

export const ButtonDelete: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <Tooltip title={title || tp('delete-tooltip')}>
      <IconButton color={'error'} {...rest}>
        {children || <DeleteForeverIcon />}
      </IconButton>
    </Tooltip>
  );
};
