import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { memo } from 'react';
import { AppThemeOptions } from 'styles/theme';

export const themeLtr = createTheme(AppThemeOptions);
const cacheLtr = createCache({
  key: 'css',
  prepend: true,
});

interface Props {
  children: React.ReactNode;
}
export const AppThemeProvider = memo<Props>(({ children }) => {
  return (
    <CacheProvider value={cacheLtr}>
      <ThemeProvider theme={themeLtr}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
});
