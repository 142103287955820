import { AppLoading } from 'components/app-loading';

import { APP_ROUTES } from 'configs';
import {
  AboutUsLayout,
  ContactUsLayout,
  EmptyLayout,
  HomepageLayout,
  LabelsLayout,
  LoginLayout,
  PrivateLayout,
  ProjectLayout,
  TeamMembersLayout,
  ThankYouLayout,
} from 'layouts';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { LABEL_SOURCE_KEYS } from 'services/label-sources';

const DashboardPage = React.lazy(() => import('pages/dashboard'));

const HomePage = React.lazy(() => import('pages/home-general'));
const HomeFactsPage = React.lazy(() => import('pages/facts'));
const HomeSeoPage = React.lazy(() => import('pages/home-seo'));

const AboutUsPage = React.lazy(() => import('pages/about-general'));
const AboutUsWorkFlowsPage = React.lazy(() => import('pages/work-flows'));

const ContactUsPage = React.lazy(() => import('pages/contact-us-general'));

const ThankYouPage = React.lazy(() => import('pages/thank-you-general'));
const ThankYouSeoPage = React.lazy(() => import('pages/thank-you-seo'));

const TeamMembersGeneralPage = React.lazy(() => import('pages/team-members-general'));
const TeamMembersPage = React.lazy(() => import('pages/team-members'));

const ProjectsListPage = React.lazy(
  () => import(/* webpackChunkName: "projects-list" */ 'pages/projects'),
);
const ProjectsItemPage = React.lazy(
  () => import(/* webpackChunkName: "projects-item" */ 'pages/projects-item'),
);

const ProjectPagePage = React.lazy(() => import('pages/project-general'));
const ProjectSpecializationPagePage = React.lazy(() => import('pages/specializations'));

const ProfilePage = React.lazy(() => import('pages/profile'));
const UsersPage = React.lazy(() => import('pages/users'));
const UserItemPage = React.lazy(() => import('pages/user-item'));
const PermissionsPage = React.lazy(() => import('pages/cms-permissions'));
const ProjectTypesPage = React.lazy(() => import('pages/project-types'));
const LeadsPage = React.lazy(() => import(/* webpackChunkName: "leads-page" */ 'pages/leads'));

const LabelsContainer = React.lazy(() => import('pages/labels'));
const LanguagesPage = React.lazy(() => import('pages/languages'));

const SystemSettingsPage = React.lazy(() => import('pages/system-settings'));

const LoginPage = React.lazy(() => import(/* webpackChunkName: "login" */ 'pages/login'));
const LoginCodePage = React.lazy(() => import('pages/login-code'));

const NotFoundPage = React.lazy(() => import('pages/todo'));

export const routes: RouteObject[] = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <DashboardPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.HOME_PAGE.path,
        element: <HomepageLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <HomePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.HOME_PAGE_FACTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <HomeFactsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.HOME_PAGE_SEO.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <HomeSeoPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.ABOUT_US.path,
        element: <AboutUsLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <AboutUsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.ABOUT_US_WORK_FLOWS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <AboutUsWorkFlowsPage />
              </React.Suspense>
            ),
          },
        ],
      },

      {
        path: APP_ROUTES.CONTACT_US.path,
        element: <ContactUsLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ContactUsPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.THANK_YOU.path,
        element: <ThankYouLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ThankYouPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.THANK_YOU_SEO.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ThankYouSeoPage />
              </React.Suspense>
            ),
          },
        ],
      },

      {
        path: APP_ROUTES.TEAM_MEMBERS.path,
        element: <TeamMembersLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <TeamMembersGeneralPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.TEAM_MEMBERS_USERS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <TeamMembersPage />
              </React.Suspense>
            ),
          },
        ],
      },

      {
        path: APP_ROUTES.PROJECTS_LIST.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProjectsListPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROJECTS_ITEM.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProjectsItemPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROJECT_PAGE.path,
        element: <ProjectLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ProjectPagePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PROJECT_PAGE_SPECIALIZATIONS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ProjectSpecializationPagePage />
              </React.Suspense>
            ),
          },
        ],
      },

      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_USERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <UsersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_USER_ITEM.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <UserItemPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PERMISSIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PermissionsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LABELS.path,
        element: <LabelsLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsContainer labelSourceKey={LABEL_SOURCE_KEYS.WEBSITE} />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsContainer labelSourceKey={LABEL_SOURCE_KEYS.CMS} />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.LANGUAGES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LanguagesPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.PROJECT_TYPES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProjectTypesPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LEADS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LeadsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SYSTEM_SETTINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <SystemSettingsPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
