import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import { useMergeRef, useOpen } from 'hooks';
import React, { forwardRef, useCallback, useMemo, useRef } from 'react';

interface Item extends Partial<Omit<MenuItemProps, 'title' | 'onClick'>> {
  title: string | React.ReactNode;
  onClick?: () => void;
}

export interface MenuButtonProps {
  items: Item[];
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  MenuProps?: Partial<MenuProps>;
  closeOnClick?: boolean;
  children: React.ReactNode;
}

export const MenuButton = forwardRef<any, MenuButtonProps>(
  (
    {
      items,
      isOpen: _isOpen,
      onClose: _onClose,
      onOpen: _onOpen,
      MenuProps,
      closeOnClick = true,
      children,
    },
    outerRef,
  ) => {
    const { isOpen: innerIsOpen, onOpen: innerOnOpen, onClose: innerOnClose } = useOpen();
    const isOpen = _isOpen === undefined ? innerIsOpen : _isOpen;
    const onClose = _onClose === undefined ? innerOnClose : _onClose;
    const onOpen = _onOpen === undefined ? innerOnOpen : _onOpen;

    const ref = useRef<any>();
    const mergedRef = useMergeRef(ref, outerRef);
    const factoryClick = useCallback(
      (handle: Item['onClick']) => {
        return () => {
          handle && handle();
          if (closeOnClick) {
            onClose();
          }
        };
      },
      [closeOnClick, onClose],
    );
    const Child = useMemo(() => {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // @ts-ignore
          return React.cloneElement(child, { ref: mergedRef, onClick: onOpen, ...child.props });
        }
        return child;
      });
    }, [children, mergedRef, onOpen]);

    return (
      <>
        {Child}
        <Menu
          anchorEl={ref.current}
          open={isOpen}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          {...MenuProps}
        >
          {items.map(({ title, onClick, ...rest }, i) => (
            <MenuItem key={i} {...rest} onClick={factoryClick(onClick)}>
              {title}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);
