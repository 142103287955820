import { apiApp, apiFree } from 'utils/service';
import { API_AUTH, CurrentAppUser, JWT } from './models';

export * from './models';

class Service {
  async refreshToken(p: { token?: string; refreshToken?: string }) {
    return apiFree.post<JWT['jwt']>(API_AUTH.REFRESH_TOKEN, p);
  }

  async getCurrentUser() {
    return apiApp.get<CurrentAppUser>(API_AUTH.GET_CURRENT_USER);
  }

  async generatePassword(data: { email: string }) {
    return apiApp.post(API_AUTH.GENERATE_PASSWORD, data);
  }

  async sendCode(data: { email: string; code: string }) {
    return apiApp.post<JWT>(API_AUTH.LOGIN, data);
  }

  async logout() {
    return apiApp.post(API_AUTH.LOGOUT);
  }
}

export const ServiceAccounts = new Service();
