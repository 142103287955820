import React from 'react';

import style from './index.module.scss';

import { Box, Tab, TabProps, Tabs, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { Link, Outlet, useLocation } from 'react-router-dom';

interface ITab extends TabProps<typeof Link> {
  value: string;
}
interface Classes {
  root: string;
  wrap: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  tabs: ITab[];
}

export const TabsPage: React.FC<Props> = ({ className, classes, tabs }) => {
  const { pathname } = useLocation();
  const isResponsive = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));
  return (
    <Box className={clsx(style.root, classes?.root, className)}>
      <Tabs
        orientation={isResponsive ? 'horizontal' : 'vertical'}
        variant={'scrollable'}
        textColor={'secondary'}
        indicatorColor={'secondary'}
        value={pathname}
        classes={{ root: style.tabs, indicator: style.tabsIndicator }}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              key={tab.value}
              color={'secondary'}
              {...tab}
              component={Link}
              classes={{ root: style.tab, selected: style.tabSelected }}
            />
          );
        })}
      </Tabs>
      <Box className={clsx(style.wrap, classes?.wrap)}>
        <Outlet />
      </Box>
    </Box>
  );
};
