import * as dynamic from 'utils/dynamic';
import { contains, decoratorIsNotNullable, DynamicOrder, equals } from 'utils/dynamic';
import { apiRtk, decoratorWithFiles, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_USERS, IUser, IUserGrid, User } from './models';

export * from './models';

type Model = User;
const REVALIDATE_TAG = 'UserCrmProfiles';

class Service extends DynamicService<Model> {
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async post(data: Model) {
    return super.post(data);
  }

  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async patch(data: Partial<Model>) {
    return super.patch({ ...data });
  }
}

export const ServiceUsers = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USERS.GET_ALL_DYNAMIC,
  post: API_USERS.POST,
  patch: API_USERS.PATCH,
  delete: API_USERS.DELETE,
});

interface UserSource extends Pick<User, 'id' | 'lastName' | 'firstName'> {}

export const apiUsers = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUsersSource: build.query<UserSource[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUsers.getAllDynamic<UserSource>({
            select: dynamic.select<Model>('appIdentityUserID as id', 'firstName', 'lastName'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUsersGrid: build.query<
      DynamicResult<IUserGrid, { count: true }>,
      {
        search: string;
        userCmsProfilePermissionID: string;
        orderBy: DynamicOrder;
        take: number;
        skip: number;
      }
    >({
      queryFn: async ({ take, skip, orderBy, search, userCmsProfilePermissionID }) => {
        try {
          const params = {
            select: dynamic.select<Model>(
              'appIdentityUserID',
              'userPhoto',
              'userCmsProfilePermission.title as role',
              'mobilePhone',
              'email',
              'firstName',
              'lastName',
              'isActive',
            ),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter<IUser>(
                  [
                    'firstName',
                    'lastName',
                    'email',
                    'mobilePhone',
                    'userCmsProfilePermission.title',
                  ],
                  search,
                  contains,
                ),
                dynamic.makeFilter<IUser>(
                  'userCmsProfilePermissionID',
                  userCmsProfilePermissionID,
                  decoratorIsNotNullable(equals),
                ),
              )
              .join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            take,
            skip,
          };
          const { data } = await ServiceUsers.getAllDynamic<IUserGrid, typeof params>(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUser: build.query<Model, string>({
      queryFn: async (appIdentityUserID) => {
        try {
          const { data } = await ServiceUsers.getDynamic(appIdentityUserID);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postUser: build.mutation<void, Model>({
      queryFn: async (data) => {
        try {
          await ServiceUsers.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUser: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUsers.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
    deleteUser: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUsers.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
  }),
});
