const API = 'LabelSources';

export const API_LABEL_SOURCES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<LabelSource, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<LabelSource, 'id'>) => `${API}/Delete/${data.id}`,
};
export enum LABEL_SOURCE_KEYS {
  CMS = 'cms',
  WEBSITE = 'website',
}
export interface LabelSource {
  id: string;
  title: string;
  key: LABEL_SOURCE_KEYS;
}
export interface ILabelSource extends LabelSource {
  labelSource: ILabelSource;
}
