import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentAppUser } from 'services/accounts';
import { actionAccountGetUser, actionAccountLogout } from 'store/auth/actions';

interface InitState {
  isInit: boolean;
  isLoading: boolean;
  error: null | Error;
  user: null | CurrentAppUser;
  languageID: null | string;
}

const initStateAccount = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    user: null,
    languageID: null,
  };
};

const slice = createSlice({
  name: 'ACCOUNT',
  initialState: initStateAccount(),
  reducers: {
    actionAccountSetLanguageID(state, action: PayloadAction<string>) {
      state.languageID = action.payload;
    },
    actionAccountClear() {
      return initStateAccount();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountGetUser.pending, (state, action) => {
      const { isOnTheBackground } = action.meta.arg;
      if (!isOnTheBackground) {
        state.isLoading = true;
      }
      state.error = null;
    });
    builder.addCase(actionAccountGetUser.fulfilled, (state, action) => {
      const user = action.payload;

      const { isOnTheBackground } = action.meta.arg;
      if (!isOnTheBackground) {
        state.isLoading = false;
      }

      state.isInit = true;
      state.user = user;
    });
    builder.addCase(actionAccountGetUser.rejected, (state, action) => {
      const { error } = action;
      const { isOnTheBackground } = action.meta.arg;
      if (!isOnTheBackground) {
        state.isLoading = false;
      }
      state.error = error;
      state.isInit = true;
    });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initStateAccount();
    });
  },
});
export const { actionAccountClear, actionAccountSetLanguageID } = slice.actions;
export const reducerAccount = slice.reducer;
