import { base64ToFileStream, ValueFileUploaderFile } from 'utils/file-uploader';
import { getRandomString } from 'utils/other';
import { apiApp, ValueHtmlContentUploader } from 'utils/service';
import { API_MEDIA_UPLOADS, MediaUpload } from './models';

export * from './models';

class Service {
  async uploadFile(data: ValueFileUploaderFile) {
    const { name, value } = data;
    const fileName = [getRandomString(20), name].filter(Boolean).join('__');
    const content = base64ToFileStream(value);

    return apiApp.post<Required<MediaUpload>>(API_MEDIA_UPLOADS.UPLOAD_FILE, {
      fileName,
      content,
    });
  }

  async uploadHtml(data: ValueHtmlContentUploader) {
    const { name, value } = data;
    const fileName = [getRandomString(20), name].filter(Boolean).join('__') + '_htmlContent.html';
    return apiApp.post<Required<MediaUpload>>(API_MEDIA_UPLOADS.UPLOAD_HTML, {
      fileName: fileName,
      filePath: 'HtmlParts',
      content: value,
    });
  }
  async remove(data: Pick<Required<MediaUpload>, 'filePath'>) {
    try {
      await apiApp.put(API_MEDIA_UPLOADS.REMOVE_FILE(data));
    } catch (e) {
      console.warn('Error to remove file');
    }
  }

  async getHtml(data: string) {
    try {
      return await apiApp.get(API_MEDIA_UPLOADS.GET_HTML(data));
    } catch (e) {
      console.warn('Error to get html file');
    }
  }
}

export const ServiceMediaUploads = new Service();
