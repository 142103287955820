import { useCallback, useEffect, useState } from 'react';
import { ServiceMediaUploads } from 'services/media-uploads';
import * as yup from 'yup';
import { useMountedRef } from './use-mounted-ref';

const schema = yup.string().url();
export const useLoadHtml = (url: string | undefined | null) => {
  const [html, setHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const mountedRef = useMountedRef();
  const onLoad = useCallback(
    async (url: string) => {
      setIsLoading(true);
      const response = await ServiceMediaUploads.getHtml(url);

      if (!mountedRef.current) return;

      if (response?.data) {
        setHtml(response?.data);
      }
      setIsLoading(false);
    },
    [mountedRef],
  );

  useEffect(() => {
    if (url && schema.isValidSync(url)) {
      onLoad(url);
    }
  }, [url, onLoad]);

  return { html, isLoading };
};
