const TOKEN_PREFIX = process.env.REACT_APP_TOKEN_PREFIX;

const APP_TOKEN = `${TOKEN_PREFIX}_token`;
const APP_REFRESH = `${TOKEN_PREFIX}_token_refresh`;
const APP_EXPIRE = `${TOKEN_PREFIX}_token_date`;

const DOMAIN = (function () {
  return window.location.hostname;
})();

const getCookies = (name: string) => {
  if (!name) return undefined;
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
const setCookies = (name: string, data = '', expires = '', domain = '') => {
  if (data) {
    document.cookie = `${name}=${encodeURIComponent(data)};${
      expires ? ` expires=${new Date(expires).toUTCString()};` : ''
    } secure; domain=${domain ? domain : DOMAIN}; samesite=lax; path=/;`;
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; domain=${
      domain ? domain : DOMAIN
    }; samesite=lax; path=/;`;
  }
};

interface TokenOptions {
  token: string;
  refreshToken: string;
  expires: string;
}

export const updateAuthTokens = ({ token, expires, refreshToken }: Partial<TokenOptions> = {}) => {
  setCookies(APP_TOKEN, token, expires, DOMAIN);
  setCookies(APP_REFRESH, refreshToken, expires, DOMAIN);
  setCookies(APP_EXPIRE, expires, expires, DOMAIN);
};
export const getAuthTokens = () => {
  return {
    token: getCookies(APP_TOKEN),
    refreshToken: getCookies(APP_REFRESH),
    expires: getCookies(APP_EXPIRE),
  };
};
