import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {}

export const SvgLogo = (props: Props) => {
  return (
    <SvgIcon {...props} width="195" height="70" viewBox="0 0 195 70">
      <path d="M44.0906 53.3276H51.2331C52.9759 53.3276 54.5242 53.672 55.878 54.3608C57.2318 55.034 58.2822 55.9968 59.0291 57.2492C59.776 58.4859 60.1495 59.9183 60.1495 61.5465C60.1495 63.1746 59.776 64.6148 59.0291 65.8672C58.2822 67.104 57.2318 68.0667 55.878 68.7556C54.5242 69.4287 52.9759 69.7653 51.2331 69.7653H44.0906V53.3276ZM51.093 67.1822C52.2912 67.1822 53.3416 66.9552 54.2441 66.5012C55.1622 66.0316 55.8624 65.3741 56.3448 64.5287C56.8428 63.6677 57.0917 62.6736 57.0917 61.5465C57.0917 60.4193 56.8428 59.433 56.3448 58.5877C55.8624 57.7266 55.1622 57.0691 54.2441 56.6151C53.3416 56.1455 52.2912 55.9107 51.093 55.9107H47.125V67.1822H51.093Z" />
      <path d="M65.4753 53.3276H68.5097V69.7653H65.4753V53.3276Z" fill="inherit" />
      <path
        d="M86.187 61.3586H89.058V67.9102C88.2177 68.5834 87.2374 69.1 86.117 69.46C84.9966 69.8201 83.8451 70.0001 82.6625 70.0001C80.9974 70.0001 79.4958 69.6401 78.1576 68.9199C76.8193 68.1842 75.769 67.1744 75.0065 65.8907C74.244 64.607 73.8627 63.1589 73.8627 61.5465C73.8627 59.934 74.244 58.4859 75.0065 57.2022C75.769 55.9185 76.8193 54.9166 78.1576 54.1964C79.5114 53.4607 81.0286 53.0928 82.7091 53.0928C84.0785 53.0928 85.3234 53.3198 86.4438 53.7738C87.5642 54.2278 88.5056 54.8931 89.2681 55.7698L87.3541 57.6484C86.1092 56.396 84.6076 55.7698 82.8492 55.7698C81.6977 55.7698 80.6707 56.0124 79.7681 56.4977C78.8811 56.983 78.1809 57.664 77.6674 58.5407C77.1694 59.4174 76.9205 60.4193 76.9205 61.5465C76.9205 62.6423 77.1694 63.6286 77.6674 64.5052C78.1809 65.3819 78.8811 66.0707 79.7681 66.5717C80.6707 67.0727 81.6899 67.3231 82.8258 67.3231C84.1018 67.3231 85.2222 67.0413 86.187 66.4778V61.3586Z"
        fill="inherit"
      />
      <path d="M95.1609 53.3276H98.1953V69.7653H95.1609V53.3276Z" fill="inherit" />
      <path
        d="M108.03 55.9107H102.615V53.3276H116.479V55.9107H111.064V69.7653H108.03V55.9107Z"
        fill="inherit"
      />
      <path
        d="M130.634 65.9612H122.464L120.854 69.7653H117.726L125.079 53.3276H128.066L135.442 69.7653H132.268L130.634 65.9612ZM129.63 63.5659L126.549 56.3803L123.491 63.5659H129.63Z"
        fill="inherit"
      />
      <path d="M139.621 53.3276H142.655V67.1822H151.198V69.7653H139.621V53.3276Z" fill="inherit" />
      <rect y="60.1738" width="37.2004" height="2.93531" fill="inherit" />
      <rect x="157.555" y="60.1738" width="37.2004" height="2.93531" fill="inherit" />
      <path d="M109.248 6.06266H115.915V0H109.248V6.06266Z" fill="inherit" />
      <path d="M109.248 39.6782H115.915V11.7899H109.248V39.6782Z" fill="inherit" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3677 39.6782H37.0351V6.06266C37.0351 5.59829 36.9496 5.15971 36.7786 4.74694C36.6077 4.33416 36.3683 3.97298 36.0606 3.6634C35.7529 3.35381 35.3939 3.11303 34.9836 2.94104C34.5733 2.76905 34.1373 2.68305 33.6758 2.68305H18.6486C15.9987 2.68305 13.554 3.13883 11.3145 4.05038C9.07495 4.96193 7.13458 6.23466 5.49339 7.86857C3.8522 9.50247 2.57001 11.4546 1.64684 13.7248C0.723671 15.9951 0.262085 18.4804 0.262085 21.1806V39.6782H6.92943V30.5971H30.3677V39.6782ZM30.3677 9.39068V23.8895H6.92943V21.1806C6.92943 19.3059 7.18587 17.715 7.69874 16.4079C8.22871 15.0836 8.89545 13.9828 9.69895 13.1057C10.5024 12.2285 11.3829 11.5406 12.3402 11.0418C13.2976 10.543 14.2037 10.1732 15.0585 9.93245C15.9304 9.69166 16.6911 9.54547 17.3408 9.49388C18.0075 9.42508 18.4434 9.39068 18.6486 9.39068H30.3677Z"
      />
      <path d="M73.9619 37.6401C74.1329 37.2273 74.2184 36.7802 74.2184 36.2986V11.7899H67.551V32.9706H55.8832C55.0797 32.9706 54.3274 32.8416 53.6265 32.5836C52.9427 32.3084 52.3443 31.9214 51.8315 31.4227C51.3186 30.9239 50.9083 30.3305 50.6006 29.6426C50.3099 28.9374 50.1646 28.1462 50.1646 27.2691V11.7899H43.4973V27.2691C43.4973 28.7826 43.6853 30.1413 44.0614 31.3453C44.4546 32.5492 44.9675 33.6155 45.6001 34.5443C46.2326 35.4558 46.9677 36.2384 47.8054 36.892C48.6602 37.5283 49.5406 38.0615 50.4467 38.4915C51.3528 38.9043 52.2674 39.2052 53.1906 39.3944C54.1308 39.5836 55.0113 39.6782 55.8319 39.6782H70.9103C71.3719 39.6782 71.7993 39.5922 72.1925 39.4202C72.6028 39.2482 72.9533 39.016 73.2439 38.7237C73.5516 38.4141 73.791 38.0529 73.9619 37.6401Z" />
      <path d="M104.734 18.4976H89.7328C88.8267 18.4976 88.1429 18.7298 87.6813 19.1941C87.2197 19.6413 86.9889 20.3035 86.9889 21.1806V39.6782H80.3216V21.1806C80.3216 20.0283 80.4669 18.9963 80.7575 18.0848C81.0481 17.1733 81.4328 16.3735 81.9115 15.6855C82.4072 14.9804 82.9714 14.387 83.6039 13.9054C84.2365 13.4067 84.8947 13.0025 85.5785 12.6929C86.2794 12.3833 86.9804 12.1597 87.6813 12.0221C88.3993 11.8673 89.066 11.7899 89.6815 11.7899H104.734V18.4976Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.483 30.2617C152.483 31.4141 152.337 32.4546 152.047 33.3834C151.756 34.2949 151.372 35.1033 150.893 35.8084C150.414 36.4964 149.859 37.0897 149.226 37.5885C148.594 38.0701 147.927 38.4657 147.226 38.7753C146.542 39.0848 145.841 39.317 145.123 39.4718C144.422 39.6094 143.755 39.6782 143.123 39.6782H131.096C130.173 39.6782 129.164 39.5148 128.07 39.188C126.976 38.8613 125.959 38.3281 125.018 37.5885C124.095 36.8318 123.317 35.86 122.685 34.6733C122.069 33.4693 121.762 31.9988 121.762 30.2617V21.1806C121.762 19.4607 122.069 18.0074 122.685 16.8207C123.317 15.6167 124.095 14.645 125.018 13.9054C125.959 13.1487 126.976 12.6069 128.07 12.2801C129.164 11.9533 130.173 11.7899 131.096 11.7899H143.123C144.832 11.7899 146.286 12.0995 147.482 12.7187C148.679 13.3379 149.645 14.1204 150.38 15.0664C151.115 15.9951 151.645 17.0099 151.97 18.1106C152.312 19.2113 152.483 20.2347 152.483 21.1806V30.2617ZM145.815 21.2322C145.815 20.3035 145.585 19.6155 145.123 19.1683C144.661 18.7212 143.995 18.4976 143.123 18.4976H131.147C130.258 18.4976 129.583 18.7298 129.121 19.1941C128.66 19.6413 128.429 20.3035 128.429 21.1806V30.2617C128.429 31.1389 128.66 31.8096 129.121 32.274C129.583 32.7384 130.258 32.9706 131.147 32.9706H143.123C144.029 32.9706 144.704 32.7384 145.149 32.274C145.593 31.8096 145.815 31.1389 145.815 30.2617V21.2322Z"
      />
      <path d="M164.894 39.6782H158.227V6.06266C158.227 5.59829 158.312 5.15971 158.483 4.74694C158.654 4.33416 158.894 3.97298 159.201 3.6634C159.509 3.35381 159.868 3.11303 160.278 2.94104C160.689 2.76905 161.125 2.68305 161.586 2.68305H176.613C179.263 2.68305 181.708 3.13883 183.948 4.05038C186.187 4.96193 188.127 6.23466 189.769 7.86857C191.41 9.50247 192.692 11.4546 193.615 13.7248C194.538 15.9951 195 18.4804 195 21.1806V39.6782H188.333V30.5971V23.8895V21.1806C188.333 19.3059 188.076 17.715 187.563 16.4079C187.033 15.0836 186.367 13.9828 185.563 13.1057C184.76 12.2285 183.879 11.5406 182.922 11.0418C181.964 10.543 181.058 10.1732 180.204 9.93245C179.332 9.69166 178.571 9.54547 177.921 9.49388C177.255 9.42508 176.819 9.39068 176.613 9.39068H164.894V23.8895V30.5971V39.6782Z" />
    </SvgIcon>
  );
};
