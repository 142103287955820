import * as yup from 'yup';

const API = 'ProjectTypes';

export const API_PROJECT_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ProjectType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ProjectType, 'id'>) => `${API}/Delete/${data.id}`,
};

export const schemaProjectTypes = yup.object({
  title: yup.string().required('rule-required'),
});

export class ProjectType implements yup.InferType<typeof schemaProjectTypes> {
  id!: string;
  title = '';
  isActive = true;
  rank = 0;
}
export interface IProjectType extends ProjectType {}
