import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';

interface Classes {
  root: string;
}

interface Props extends ButtonProps {
  className?: string;
  classes?: Partial<Classes>;
}

export const ButtonCreate: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  const _title = useMemo(() => {
    if (children === tp('create')) {
      return tp('create-tooltip');
    }
    if (children === tp('save')) {
      return tp('save-tooltip');
    }
    return tp('submit');
  }, [children, tp]);
  return (
    <Tooltip open={rest.disabled ? false : undefined} title={title || _title}>
      <Button variant={'contained'} color={'secondary'} {...rest}>
        {children || tp('create')}
      </Button>
    </Tooltip>
  );
};
