import { useMemo } from 'react';
import { apiLabelSources } from 'services/label-sources';
import { apiLanguages, DIRECTION } from 'services/languages';
import { apiProjectTypes } from 'services/project-types';
import { apiUserPermissions } from 'services/user-permissions';
import { ArrayType, enumToArray } from 'utils/types';
import { useMap } from './use-map';
import { useTranslate } from './use-translate';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
export const useSourceDataMap = <T extends { data: any[] }>(
  v: T,
  mapKey: keyof ArrayType<T['data']>,
) => {
  const map = useMap<ArrayType<T['data']>>(v.data, String(mapKey));
  return useMemo(() => ({ ...v, map }), [v, map]);
};
export const useSourceTranslate = <T extends { data: any[] }>(
  v: T,
  translateKey: keyof ArrayType<T['data']>,
) => {
  const { t } = useTranslate();

  const data = useMemo(() => {
    return v.data.map((item) => ({ ...item, title: t(item[translateKey]) }));
  }, [v.data, t, translateKey]);
  return useMemo(() => {
    const { data: _, ...rest } = v;
    return {
      ...rest,
      data: data as (ArrayType<T['data']> & { title: string })[],
    };
  }, [v, data]);
};
export const useSourcePermissions = () => {
  return useSourceData(apiUserPermissions.useGetUserCmsProfilePermissionSourceQuery());
};
export const useSourceProjectTypes = () => {
  return useSourceData(apiProjectTypes.useGetProjectTypesSourceQuery());
};
export const useSourceLabelSources = () => {
  return useSourceData(apiLabelSources.useGetAllLabelSourcesQuery());
};
export const useSourceLanguages = () => {
  return useSourceData(apiLanguages.useGetAllLanguagesQuery());
};

export const useSourceDirections = () => {
  const { t } = useTranslate();
  return useMemo(() => {
    return { data: enumToArray(DIRECTION).map((item) => ({ ...item, title: t(item.title) })) };
  }, [t]);
};
