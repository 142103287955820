import { HEADER_PORTAL_ID } from 'configs/const';
import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  children: React.ReactNode;
}

export const HeaderPortal: React.FC<Props> = ({ children }) => {
  const [el, setElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    setElement(document.getElementById(HEADER_PORTAL_ID));
  }, []);

  if (!el) return null;

  return ReactDOM.createPortal(children, el);
};
