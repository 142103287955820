import { Box, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { SvgLogo } from 'components/svg-icons/logo';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const { t } = useTranslate();

  return (
    <Box className={style.wrap}>
      <Box className={style.header}></Box>
      <Box className={style.content}>
        <Box className={style.form}>
          <SvgLogo
            color={'primary'}
            width={'195px'}
            height={'70px'}
            sx={{ width: 195, height: 70, mb: 2.9 }}
          />
          <Outlet />
        </Box>
      </Box>
      <Box className={style.footer}>
        <Typography component={'div'} variant={'caption'} color={VARS.colorGrey}>
          {t('copyright')}
        </Typography>
      </Box>
    </Box>
  );
});
