import { useAppSelector } from 'hooks/redux';
import { selectAccountPermissions, selectAccountUser } from 'store/auth';

export const useCurrentUser = () => {
  const user = useAppSelector(selectAccountUser);

  if (!user) {
    throw new Error('useCurrentUser: use private only');
  }

  return user;
};

export const usePermissions = () => {
  const permissions = useAppSelector(selectAccountPermissions);

  if (!permissions) {
    throw new Error('usePermissions: use private only');
  }

  return permissions;
};
