import { LABEL_SOURCE_KEYS } from 'services/label-sources';
import { contains, dynamicNamespace, DynamicOrder, equals, mergeFilters } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_LABELS, IGridLabel, ILabel, Label } from './models';

const REVALIDATE_KEY = 'Labels' as const;

const dynamic = dynamicNamespace<ILabel>();

export * from './models';

class Service extends DynamicService<Label> {
  async post(data: Label) {
    return super.post({ ...data, labelKey: data.labelKey.trim() });
  }
  async patch(data: Partial<Label>) {
    return super.patch({
      ...data,
      ...(data.labelKey
        ? {
            labelKey: data.labelKey.trim(),
          }
        : {}),
    });
  }
}

type ApiModel = Label;

export const ServiceLabels = new Service({
  getAll: API_LABELS.GET_ALL_DYNAMIC,
  post: API_LABELS.POST,
  patch: API_LABELS.PATCH,
  delete: API_LABELS.DELETE,
});

export const apiLabels = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAppLabels: build.query<ApiModel[], { languageID: string }>({
      queryFn: async ({ languageID }) => {
        try {
          const {
            data: { value },
          } = await ServiceLabels.getAllDynamic({
            select: dynamic.select('id', 'labelKey', 'title'),
            filter: mergeFilters(
              dynamic.makeFilter('languageID', languageID, equals),
              dynamic.makeFilter('labelSource.key', LABEL_SOURCE_KEYS.CMS, equals),
              dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridLabels: build.query<
      DynamicResult<IGridLabel, { count: true }>,
      {
        labelSourceID: string;
        languageID: string;
        search: string;
        take: number;
        skip: number;
        orderBy: DynamicOrder;
      }
    >({
      queryFn: async ({ labelSourceID, languageID, search, take, skip, orderBy }) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'labelKey',
              'title',
              'isActive',
              'language.title as languageTitle',
            ),
            filter: mergeFilters(
              dynamic.makeFilter(['title', 'labelKey'], search, contains),
              dynamic.makeFilter('languageID', languageID, equals),
              dynamic.makeFilter('labelSourceID', labelSourceID, equals),
            ).join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceLabels.getAllDynamic<IGridLabel, typeof params>(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLabel: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceLabels.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLabel: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLabel: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteLabel: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
