import { Box } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Panel } from './components';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  return (
    <Box className={style.root}>
      <Box className={style.header}>
        <Header />
      </Box>
      <Box className={style.panel}>
        <Panel />
      </Box>
      <Box className={style.content}>
        <Outlet />
      </Box>
    </Box>
  );
});
