const API = 'UserCmsProfilePermissions';

export const API_USER_PERMISSIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPermissions, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPermissions, 'id'>) => `${API}/Delete/${data.id}`,
};

export class UserPermissions {
  id!: string;
  title = '';
  isActive = true;
  isAllowToEditCrmUsers = true;
  isAllowToEditLabels = false;
  isAllowToEditPermissions = true;
  isAllowToEditProfile = true;
  isAllowToEditSetting = false;
  isAllowToResetSensitiveInformation = true;
  isAllowToViewLog = true;
  isAllowToViewSensitiveInformation = true;
}

export interface IUserPermissions extends UserPermissions {}

export interface IUserPermissionsGrid extends UserPermissions {}
