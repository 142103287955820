import { UserPermissions } from 'services/user-permissions';
import * as yup from 'yup';

const API = 'UserCmsProfiles';

export const API_USERS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<User, 'appIdentityUserID'>) => `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<User, 'appIdentityUserID'>) => `${API}/Delete/${data.appIdentityUserID}`,
};

export const schemaUser = yup.object({
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  email: yup.string().nullable().required('rule-required').email('rule-email'),
  languageID: yup.string().nullable().required('rule-required'),
  userCmsProfilePermissionID: yup.string().nullable().required('rule-required'),
});
export class User implements yup.InferType<typeof schemaUser> {
  id!: string;
  isActive = true;
  appIdentityUserID!: string;
  languageID!: string;
  email = '';
  mobilePhone = '';
  userPhoto = '';
  firstName = '';
  lastName = '';
  userCmsProfilePermissionID!: string;
}

export interface IUser extends User {
  userCmsProfilePermission: UserPermissions;
}

export interface IUserGrid
  extends Pick<
    User,
    'appIdentityUserID' | 'userPhoto' | 'email' | 'firstName' | 'lastName' | 'isActive'
  > {
  role: string;
}
