import { apiUserPermissions } from 'services/user-permissions';
import { debounce, put } from 'typed-redux-saga';
import { actionAccountChangeData, actionAccountGetUser } from './actions';

function* watcherAccountChange() {
  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
}
export const sagasAccounts = [
  debounce(
    5000,
    [
      actionAccountChangeData.fulfilled,
      apiUserPermissions.endpoints.patchUserCmsProfilePermission.matchFulfilled,
    ],
    watcherAccountChange,
  ),
];
