import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, memo, useMemo } from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
  trackVisibility?: 'hover' | 'all';
}

const Component = (
  { className, children, onScroll, trackVisibility = 'hover', ...rest }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const trackVisibilityClass = useMemo(() => {
    switch (trackVisibility) {
      case 'all':
        return style.all;
      default:
        return null;
    }
  }, [trackVisibility]);
  return (
    <Box
      ref={ref}
      height={'100%'}
      overflow={'auto'}
      position={'relative'}
      className={clsx(style.root, className, trackVisibilityClass)}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const NativeScroll = memo(forwardRef(Component)) as typeof Component;
