import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';
import { reducerAccount, sagasAccounts } from './auth';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  account: reducerAccount,

  [apiRtk.reducerPath]: apiRtk.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

function* rootSaga() {
  yield all([...sagasAccounts]);
}

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
