import {
  AppConfigProvider,
  AppNotificationsProvider,
  AppThemeProvider,
  AuthProvider,
  AxiosInterceptorsProvider,
  DatePickerProvider,
  TranslateProvider,
} from 'contexts';

import { ErrorBoundary } from 'components/error-boundary';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { AppRoutes } from 'router';
import { store } from 'store';
import 'styles/app.global.scss';

function App() {
  return (
    <AppThemeProvider>
      <ErrorBoundary>
        <AppConfigProvider>
          <AppNotificationsProvider>
            <Provider store={store}>
              <AxiosInterceptorsProvider>
                <TranslateProvider>
                  <AuthProvider>
                    <DatePickerProvider>
                      <DndProvider backend={HTML5Backend}>
                        <AppRoutes />
                      </DndProvider>
                    </DatePickerProvider>
                  </AuthProvider>
                </TranslateProvider>
              </AxiosInterceptorsProvider>
            </Provider>
          </AppNotificationsProvider>
        </AppConfigProvider>
      </ErrorBoundary>
    </AppThemeProvider>
  );
}

export default App;
