import { AppLoading } from 'components/app-loading';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { apiApp, apiFree } from 'utils/service';

const instances = [apiApp, apiFree];

interface Config {
  api: string;
}

interface Props {
  children: React.ReactNode;
}

export const AppConfigProvider = memo<Props>(({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const getConfig = useCallback(async () => {
    const result = await fetch('/config.json');
    const config: Config = await result.json();

    const apiBaseUrl = process.env.REACT_APP_API_SERVER || config.api;

    if (apiBaseUrl === '#{REACT_APP_API_SERVER}#') {
      setError(new Error('Api was not provided'));
    }

    instances.forEach((apiInstance) => {
      apiInstance.defaults.baseURL = apiBaseUrl;
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  if (error) {
    throw error;
  }

  if (isLoading) return <AppLoading />;

  return <>{children}</>;
});
